import React from 'react';
import styled from 'styled-components';
import GenericGridBlock from 'components/GenericGridBlock';
import Typography from 'components/Typography';

export interface Detail {
  name: string,
  value: number | string,
}

export const isDetail = (input: any): input is Detail => !!(
  /* eslint-disable @typescript-eslint/no-unsafe-member-access */
  input?.name != null
  && input?.value != null
  && input?.name !== ''
  && input?.value !== ''
  /* eslint-enable @typescript-eslint/no-unsafe-member-access */
);

export interface Props {
  title?: string,
  details: Detail[],
}

const DetailsBlock:React.FC<Props> = ({ title = 'Details.', details }) => {
  return (
    <DetailsBlockOuter>
      <GenericGridBlock title={title} itemsPerRow={4} tileMarginBottom="0">
        {details.map(({ name, value }) => (
          <DetailOuter key={name}>
            <Typography bold>{name}</Typography>
            <Typography>{value}</Typography>
          </DetailOuter>
        ))}
      </GenericGridBlock>
    </DetailsBlockOuter>
  );
};
export default DetailsBlock;

const DetailsBlockOuter = styled.div``;
const DetailOuter = styled.div``;
