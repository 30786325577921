import { nanoid } from 'nanoid';
import React from 'react';
import styled from 'styled-components';
import Marker, { Props as MarkerProps } from './Marker';

type ListTypeFullName = 'ordered' | 'unordered';

export interface Props {
  children: React.ReactNode[],
  type?: ListTypeFullName,
  marker?: MarkerProps['marker'],
  markerColor?: React.CSSProperties['backgroundColor'],
}

const List:React.FC<Props> = ({
  children,
  type = 'unordered',
  marker,
  markerColor,
}) => {
  const typeMap: Record<ListTypeFullName, 'ul' | 'ol'> = {
    ordered: 'ol',
    unordered: 'ul',
  };
  const childrenWithKeys = children.map((child) => ({
    child,
    key: nanoid(),
  }));
  return (
    <ListOuter
      className={[
        ...(marker ? ['custom-marker'] : []),
        ...(type ? [`${type}-type`] : []),
      ].join(' ')}
      as={typeMap[type]}
    >
      {childrenWithKeys.map(({ child, key }, i) => (
        <ListItem key={key}>
          <Inner>
            {marker && (
              <MarkerWrap>
                <Marker marker={marker} color={markerColor} index={i} />
              </MarkerWrap>
            )}
            <ItemContent>{child}</ItemContent>
          </Inner>
        </ListItem>
      ))}
    </ListOuter>
  );
};
export default List;

const ListOuter = styled.div`
  padding: 0;
  margin: 0;
  margin-left: 2em;
  &.custom-marker {
    list-style-type: none;
    margin-left: 0.4em;
  }
  &.unordered-type {}
  &.ordered-type {}
`;
const ListItem = styled.li`
  margin-bottom: 1em;
`;
const Inner = styled.div`
  display: flex;
`;
const MarkerWrap = styled.div`
  margin-top: 0.15em;
  margin-right: 0.5em;
`;
const ItemContent = styled.div``;
