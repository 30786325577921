import React from 'react';
import styled from 'styled-components';
import { FormWrap } from 'components/formElements';
import HubspotFormWithFileUpload from 'src/lib/components/HubspotForm/WithFileUpload';
import defaultFormStyles from 'src/components/formElements/defaultFormStyles';
import { requireEnv } from 'src/helpers';
import { fieldDefinitions } from './fields';

export interface Props {
  pageName?: string,
  pageUri?: string,
}

const CareerApplicationForm:React.FC<Props> = ({ pageName, pageUri }) => {
  const hubspotFolderId = requireEnv(process.env.GATSBY_HUBSPOT_CV_UPLOADS_ID);
  const hubspotFormGuId = requireEnv(process.env.GATSBY_HUBSPOT_CAREERS_FORM_GUID);
  const hubspotPortalId = requireEnv(process.env.GATSBY_HUBSPOT_CAREERS_FORM_PORTAL_ID);

  return (
    <CareerApplicationFormOuter>
      <FormWrap>
        <HubspotFormWithFileUpload
          formName="career-application-form"
          fieldDefinitions={fieldDefinitions}
          hubspotFolderId={Number(hubspotFolderId)}
          formGuid={hubspotFormGuId}
          portalId={hubspotPortalId}
          context={{ pageName, pageUri }}
          {...defaultFormStyles}
        />
      </FormWrap>
    </CareerApplicationFormOuter>
  );
};
export default CareerApplicationForm;

const CareerApplicationFormOuter = styled.div``;
