import React from 'react';
import { graphql } from 'gatsby';
import Container from 'components/Container';
import Block from 'components/Block';
import ShiftBlock from 'components/ShiftBlock';
import PageHeader from 'components/PageHeader';
import Breadcrumbs, { Props as BreadcrumbsProps } from 'src/components/Breadcrumbs';
import DetailsBlock, { Detail, isDetail } from 'src/components/DetailsBlock';
import { formatCurrency, resolveUrl } from 'src/helpers';
import Seo from 'src/lib/components/Seo';
import CareerQuery_Career from 'src/transformers/CareerQuery_Career';
import { Career } from 'src/models/Career';
import { format } from 'src/helpers/date';
import { headerCase } from 'change-case';
import Typography from 'src/components/Typography';
import List from 'src/components/List';
import { ThemeContext } from 'styled-components';
import GenericGridBlock from 'src/components/GenericGridBlock';
import Spacer from 'src/components/Spacer';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import CareerApplicationForm from 'components/CareerApplicationForm';
import JobPostingSchema from 'src/components/schemas/JobPostingSchema';
import ContentParser from 'src/components/ContentParser';
import BasicQuery_SitePage from 'src/transformers/BasicQuery_SitePage';
import { useLocation } from '@reach/router';

interface Props {
  data: GatsbyTypes.CareerSingleQuery,
  // pageContext: { caseStudyId: string },
}

export const query = graphql`
  query CareerSingle($careerId: String) {
    wpCareer(id: { eq: $careerId }) {
      ...CareerBasic
      acfSeo {
        metaDescription
        metaTitle
        navTitle
      }
    }
  }
`;

const getSalaryString = (salary: Career['salary']): string | undefined => {
  const min = salary?.minimum;
  const max = salary?.maximum;
  if (!min || !max) return undefined;
  if (min === max) return formatCurrency(min);
  return `${formatCurrency(min)} - ${formatCurrency(max)}`;
};


const CareerPage: React.FC<Props> = ({ data }) => {
  const theme = React.useContext(ThemeContext);
  const { href } = useLocation();
  const careerRaw = data.wpCareer;
  const career = CareerQuery_Career(careerRaw);
  if (career === null) return null;

  const pageData = BasicQuery_SitePage(careerRaw || {});

  const breadcrumbs: BreadcrumbsProps['items'] = [
    { label: 'Careers', url: resolveUrl.career('') },
    { label: pageData.navTitle },
  ];

  const detailsPartials: Array<Partial<Detail>> = [
    {
      name: 'Application Closing Date',
      value: (career.dateClosing && format(career.dateClosing)) || undefined,
    },
    {
      name: 'Position Start Date',
      value: (career.startDate && format(career.startDate)) || undefined,
    },
    {
      name: 'Employment Type',
      value: headerCase(career.employmentType),
    },
    {
      name: 'Required Years Experience',
      value: career.requiredYearsOfExperience,
    },
    {
      name: 'Number of Vacancies',
      value: career.numberOfVacancies > 1 ? career.numberOfVacancies : undefined,
    },
    {
      name: 'Remote Working',
      value: career.isFullyRemote ? 'yes' : undefined,
    },
  ];
  const details = detailsPartials.filter(isDetail);

  const essentialAndDesirablePoints: Array<{ key: string, points: string[] }> = [
    ...(career.essentialPoints.length ? [{
      key: 'Essential',
      points: career.essentialPoints,
    }] : []),
    ...(career.desirablePoints.length ? [{
      key: 'Desirable',
      points: career.desirablePoints,
    }] : []),
  ];

  return (
    <>
      <JobPostingSchema career={career} />
      <Seo
        title={pageData.metaTitle}
        // titlePath={['Careers']}
        description={pageData.metaDescription}
      />
      <PageHeader
        category="Careers"
        h1={pageData.h1}
        text={getSalaryString(career.salary)}
        backgroundText={pageData.contentHtml}
      />
      <Container>
        <Breadcrumbs items={breadcrumbs} />
        <Block>
          <ContentParser>{pageData.contentHtml}</ContentParser>
        </Block>
      </Container>

      {career.responsibilities.length > 0 && (
        <ShiftBlock title="Responsibilities.">
          <List marker="line" markerColor={theme.palette.secondary}>
            {career.responsibilities.map((responsibility) => (
              <Typography key={responsibility} variant="hero2">{responsibility}</Typography>
            ))}
          </List>
        </ShiftBlock>
      )}

      {essentialAndDesirablePoints.length > 0 && (
        <Block contain highlightBg>
          <GenericGridBlock title="The Candidate." itemsPerRow={essentialAndDesirablePoints.length}>
            {essentialAndDesirablePoints.map(({ key, points }) => (
              <React.Fragment key={key}>
                <Typography variant="h2" as="p">{key}</Typography>
                <Spacer mb={2} />
                <List marker={faCheck}>
                  {points.map((point) => (
                    <Typography key={point} variant="hero2">{point}</Typography>
                  ))}
                </List>
              </React.Fragment>
            ))}
          </GenericGridBlock>
        </Block>
      )}

      {details.length && (
        <Block darkBg contain>
          <DetailsBlock details={details} />
        </Block>
      )}

      <Block title="Apply." contain>
        <Container width="40em" marginAuto>
          <CareerApplicationForm
            pageName={career.title}
            pageUri={href}
          />
        </Container>
      </Block>
    </>
  );
};

export default CareerPage;
