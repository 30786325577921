import React from 'react';
import Block, { Props as BlockProps } from 'components/Block';
import Container from 'src/components/Container';

export type Props = BlockProps;

const ShiftBlock:React.FC<Props> = ({ children, readableWidth, ...blockProps }) => {
  return (
    <Block contain {...blockProps}>
      <Container
        readableWidth
        yPadding={0}
        xPadding={0}
        shift
      >
        {children}
      </Container>
    </Block>
  );
};
export default ShiftBlock;
