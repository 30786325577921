import React from 'react';
import styled from 'styled-components';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import isFontAwesomeIcon from 'src/types/guards/isFontAwesomeIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const markerNames = [
  'line',
  'circle',
] as const;

// const isNamedMarker = (input: any): input is MarkerName => markerNames.includes(input);

export type MarkerName = typeof markerNames[number];

export interface Props {
  marker: IconDefinition | MarkerName | React.FC<Omit<Props, 'marker'>>,
  index: number,
  color?: React.CSSProperties['color'],
}


const Marker:React.FC<Props> = ({ marker, index, color }) => {
  if (isFontAwesomeIcon(marker)) return <FontAwesomeIcon color={color} icon={marker} />;
  if (typeof marker === 'function') {
    const TheMarker = marker;
    return <TheMarker index={index} color={color} />;
  }

  const markerMap: Record<MarkerName, React.ComponentType<any>> = {
    line: LineMarker,
    circle: CircleMarker,
  };
  const MarkerComponent = markerMap[marker];
  return <MarkerComponent $color={color} />;
};
export default Marker;


const LineMarker = styled.div<{ $color: Props['color'] }>`
  margin-top: 0.5em;
  width: 2em;
  height: 3px;
  background-color: ${(p) => p.$color || p.theme.palette.text};
`;

const CircleMarker = styled.div<{ $color: Props['color'] }>`
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background-color: ${(p) => p.$color || p.theme.palette.text};
`;
