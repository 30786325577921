import React from 'react';
import { toBase64 } from 'src/lib/helpers';
import HubspotForm, { Props as HubspotFormProps } from '.';

type Props = HubspotFormProps & {
  hubspotFolderId: number,
  endpointUrl?: string,
};

const HubspotFormWithFileUpload:React.FC<Props> = ({
  hubspotFolderId,
  endpointUrl = '/.netlify/functions/upload',
  ...restProps
}) => {
  const handleSubmit: HubspotFormProps['onSubmitOverride'] = async (
    vals,
    originalSubmitFunction,
  ) => {
    const fileFields = Object.entries(vals).filter((entry) => entry[1] instanceof File);
    if (!fileFields.length) {
      await originalSubmitFunction(vals);
      return;
    } else if (fileFields.length > 1) {
      throw new Error('Multiple file fields found');
    }

    const [[fileUploadKey]] = fileFields;


    /*
      eslint-disable
      @typescript-eslint/no-unsafe-argument,
      @typescript-eslint/no-unsafe-assignment,
      @typescript-eslint/no-unsafe-member-access
    */
    const file = await toBase64(vals[fileUploadKey]);
    const filename: string = vals[fileUploadKey]?.name || 'untitled';
    /* eslint-enable */

    const options: RequestInit = {
      method: 'POST',
      cache: 'no-cache',
      redirect: 'follow',
      body: JSON.stringify({
        file,
        filename,
        folderId: hubspotFolderId,
      }),
    };

    // allowed to potentially throw as this is caught in Form
    const res = await window.fetch(endpointUrl, options);
    if (!res.ok) throw new Error(`${res.status}: ${res.statusText}`);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const json: Record<string, unknown> = await res.json();
    if (typeof json.url !== 'string') throw new Error('Missing file URL in response');
    const { url } = json;
    const valsWithFileReplaced = {
      ...vals,
      [fileUploadKey]: url,
    };
    await originalSubmitFunction(valsWithFileReplaced);
  };

  return (
    <HubspotForm
      {...restProps}
      onSubmitOverride={handleSubmit}
    />
  );
};
export default HubspotFormWithFileUpload;
