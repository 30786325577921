import React from 'react';
import { JobPosting } from 'schema-dts';
import SchemaBase from 'lib/components/schemas/SchemaBase';
import { Career } from 'src/models/Career';
import useContactSettings from 'src/hooks/queries/useContactSettings';
import useSiteSettings from 'src/hooks/queries/useSiteSettings';
import { resolveUrl } from 'src/helpers';
import companyLogo from 'assets/images/blueclaw-logo.svg';
import useGeneralSettings from 'src/hooks/queries/useGeneralSettings';


interface Props {
  career: Career,
}


const JobPostingSchema:React.FC<Props> = ({ career }) => {
  const contactSettings = useContactSettings();
  const siteSettings = useSiteSettings();
  const generalSettings = useGeneralSettings();

  const schema: JobPosting & { directApply: boolean } = {
    '@type': 'JobPosting',
    baseSalary: {
      '@type': 'MonetaryAmount',
      currency: 'GBP',
      value: {
        '@type': 'QuantitativeValue',
        minValue: career.salary?.minimum,
        maxValue: career.salary?.maximum,
        unitText: 'YEAR',
      },
    },
    datePosted: career.datePosted.toString(),
    description: career.description,
    directApply: true,
    employmentType: career.employmentType,
    hiringOrganization: {
      '@type': 'Organization',
      name: contactSettings.companyName,
      sameAs: generalSettings.url,
      logo: companyLogo,
    },
    image: career.image?.images.fallback?.src,
    industry: siteSettings.industries,
    jobLocation: {
      '@type': 'Place',
      address: {
        '@type': 'PostalAddress',
        streetAddress: contactSettings.addressTopLine,
        addressLocality: contactSettings.addressCity,
        addressRegion: contactSettings.addressRegion,
        postalCode: contactSettings.addressPostCode,
        addressCountry: contactSettings.addressCountry,
      },
    },
    jobLocationType: career.isFullyRemote ? 'TELECOMMUTE' : undefined,
    jobStartDate: career.startDate?.toString(),
    responsibilities: career.responsibilities,
    salaryCurrency: 'GBP',
    title: career.title,
    totalJobOpenings: career.numberOfVacancies || 1,
    url: resolveUrl.career(career.slug),
    validThrough: career.dateClosing?.toString(),
  };
  return <SchemaBase schema={schema} />;
};
export default JobPostingSchema;
