// import React from 'react';
import {
  string as yupString,
  boolean as yupBoolean,
} from 'yup';
import { IField } from 'lib/components/Form/form.types';
import { ConsentInnerWrap, ConsentLabel } from 'components/formElements';

const common: Partial<IField> = {};

const phoneRegExp = /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/;

export const fieldDefinitions: IField[] = [
  {
    ...common,
    slug: 'firstname',
    label: 'First Name',
    initialValue: '',
    inputType: 'text',
    validator: yupString()
      .max(50, 'Too Long')
      .required('Required'),
  },
  {
    ...common,
    slug: 'lastname',
    label: 'Last Name',
    initialValue: '',
    inputType: 'text',
    validator: yupString()
      .max(50, 'Too Long')
      .required('Required'),
  },
  {
    ...common,
    slug: 'phone',
    label: 'Phone Number (optional)',
    initialValue: '',
    inputType: 'text',
    validator: yupString()
      .matches(phoneRegExp, 'Phone number is not valid')
      .min(7, 'Phone number is not valid')
      .max(11, 'Phone number is not valid'),
  },
  {
    ...common,
    slug: 'linkedinbio',
    label: 'LinkedIn page (optional)',
    initialValue: '',
    inputType: 'text',
    validator: yupString(),
  },
  {
    ...common,
    slug: 'email',
    label: 'Email',
    initialValue: '',
    inputType: 'text',
    validator: yupString()
      .email('Invalid Email')
      .required('Required'),
  },
  {
    ...common,
    slug: 'consent',
    label: 'I agree that Blueclaw may use the data I have provided to contact me in regards to this inquiry.',
    hubspotSubscriptionTypeId: 7587888,
    initialValue: false,
    inputType: 'checkbox',
    innerWrapComponent: ConsentInnerWrap,
    labelComponent: ConsentLabel,
    validator: yupBoolean()
      .isTrue('You must agree to this in order for us to respond to you'),
  },
  {
    ...common,
    slug: 'message',
    label: 'Your Message',
    initialValue: '',
    // inputType: 'textarea',
    as: 'textarea',
    validator: yupString()
      .max(400, 'Too Long')
      .required('Required'),
  },
  {
    ...common,
    slug: 'file_upload',
    initialValue: '',
    label: 'CV Upload (max 1mb)',
    inputType: 'file',
    onChange: (event, formInfo) => {
      const files = event?.currentTarget?.files || [];
      const firstFile = files[0];
      formInfo.setFieldValue('file_upload', firstFile);
    },
  },
];
