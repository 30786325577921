import { useStaticQuery, graphql } from 'gatsby';

export default (): GatsbyTypes.SiteSettingsSingleFragment | Record<string, never> => {
  const result = useStaticQuery<GatsbyTypes.SiteSettingsQuery>(graphql`
    fragment SiteSettingsSingle on WpSiteSettings_Acf {
      businessName
      businessLogo {
        localFile {
          childImageSharp {
            fixed(width: 150) {
              src
            }
          }
        }
      }
      industries
      companyValues {
        name
        description
      }
    }
    query SiteSettings {
      allWp {
        nodes {
          siteSettings {
            acf {
              ...SiteSettingsSingle
            }
          }
        }
      }
    }
  `);
  return result.allWp.nodes[0].siteSettings?.acf || {};
};
